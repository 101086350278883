import React, { useEffect, useRef } from 'react';
import { connect } from "react-redux";
import { useLocation } from "react-router-dom";
import { AppNavBar, AppErrorBoundry, ConfirmationBox } from "../../components";
import { CircularProgress, Snackbar, Alert } from '@mui/material';
import {
  getLoading, getAlert, clearAlert, validateToken, isLoggedOut, logout, getUser, fetchTaskCustomTypeSources, encyptDataObject,
  fetchTaskPriorities, fetchTaskStatus, fetchTaskAssements, getConfirmation, setConfirmation, fetchTaskActivities, isAdmin, isArchitect, fetchIncidents
} from "../../store";

const ConsoleEndPoint = `https://console-sandbox.${process.env.REACT_APP_MAIN_DOMAIN}`

const HomeScreen = (props) => {
  const { user } = props;
  const _timerRef = useRef()
  const location = useLocation();
  //redirect user to a login page while preserving information about current URL(pathname & search) for redirection after login
  const navigateToLogin = () => {
    const redirectData = {};
    const { pathname, search } = location;
    if (pathname !== '') {
      redirectData.path = pathname;
    }
    if (search !== '') {
      redirectData.search = search;
    }
    let path = ConsoleEndPoint + '/login?source=arc';
    if (Object.keys(redirectData).length > 0) {
      let redirect = encyptDataObject(redirectData)
      path += `&redirect=${redirect}`
    }
    window.location.assign(path);
  }
  useEffect(() => {
    if (user === 'Error') {
      navigateToLogin();
    } else if (user) {
      props.fetchDefinedList()
    }
  }, [user])
  useEffect(() => {
    props.validateToken();
  }, [])
  useEffect(() => {
    if (props.loggedOut) {
      window.location.assign(ConsoleEndPoint + "/logout?source=arc");
    }
  }, [props.loggedOut])
  useEffect(() => {
    if (props.confirmation) {
      _timerRef.current = setTimeout(closeConfirmation, 3000);
    }
  }, [props.confirmation]);
  const closeConfirmation = () => {
    if (_timerRef.current) {
      clearTimeout(_timerRef.current);
    }
    props.setConfirmation(null)
  }
  return (
    <>
      {
        user && user !== 'Error' ?
          <>
            <AppNavBar user={user} logout={props.logout} isAdmin={isAdmin(user)} isArchitect={isArchitect(user)} />
            <main className='col flex-full main'>
              <AppErrorBoundry>
                {props.children}
              </AppErrorBoundry>
            </main>
            {
              Boolean(props.alert) &&
              <Snackbar
                anchorOrigin={{ horizontal: 'right', vertical: 'top' }} open autoHideDuration={6000} onClose={props.clearAlert}>
                <Alert
                  onClose={props.clearAlert}
                  severity={props.alert.severity}
                  sx={{ width: '100%' }}>
                  <span className='h7'>{props.alert.message}</span>
                </Alert>
              </Snackbar>
            }
            {
              Boolean(props.confirmation) &&
              <ConfirmationBox
                {...props.confirmation}
                onOk={closeConfirmation}
              />
            }
          </>
          :
          <div className='col flex-ctr full jg-login'>
            <div className='h5'>Please wait...</div>
          </div>
      }
      {
        props.isLoading &&
        <div className='full col flex-ctr loading'>
          <CircularProgress />
        </div>
      }
    </>
  )
}

const mapDispatchToProps = (dispatch) => {
  return {
    clearAlert: () => dispatch(clearAlert()),
    setConfirmation: (d) => dispatch(setConfirmation(d)),
    validateToken: () => dispatch(validateToken()),
    logout: () => dispatch(logout()),
    fetchDefinedList: () => {
      dispatch(fetchTaskPriorities())
      dispatch(fetchTaskStatus())
      dispatch(fetchTaskAssements())
      dispatch(fetchTaskActivities())
      dispatch(fetchIncidents())
      dispatch(fetchTaskCustomTypeSources())
    },
  }
}
const mapStateToProps = (state) => {
  return {
    invalidToken: false,
    loggedOut: isLoggedOut(state),
    isLoading: getLoading(state),
    user: getUser(state),
    alert: getAlert(state),
    confirmation: getConfirmation(state)
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(HomeScreen);
